/* globals debug utils events ResumableUploadGcs ResumableUploadB2 fetch jwtClient */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:cloudStore')

  var MIN_CHUNK_SIZE = {
    gcs: 256 * 1024, // 256kb min size for cloud uploads
    b2: 5 * 1024 * 1024, // 5 MB min size for cloud uploads
  }

  var CloudStore = (function () {
    var CloudStore = function (config) {
      this.provider = config.provider // 'b2' or 'gcs'
      if (!['gcs', 'b2'].includes(this.provider)) throw new Error('Provider (b2 or gcs) not set.')

      this.name = config.name || this.constructor.name
      this.format = config.format
      this.gatewayUploadCreatorUrl = config.gatewayUploadCreatorUrl
      this.customHostForFinalizationNotification = config.customHostForFinalizationNotification

      this.bytesUploaded = 0
      this.parts = (config.uploadedParts || []).map(x => x)
      // if the file if finalized in GCS
      // this is a helpful state because it can get out of sync with the track's finalized state
      this.finalized = false
      this.uploadUrl = config.uploadUrl
      this.downloadUrl = null
      this.chunkSize = Math.max(config.chunkSize || 0, MIN_CHUNK_SIZE[this.provider]) // 5 MB
      if (config.chunkSize < MIN_CHUNK_SIZE[this.provider])
        console.warn(`Chunk size ${config.chunkSize} is smaller than the minimum.`)

      this.origin = global.location.origin
      this.mimeType = config.mimeType
    }

    CloudStore.prototype.getMimeType = function () {
      var format = this.format
      // Soundboard samples will give their own mimeType
      //    as they do not have the same restrictions as tracks
      // If the mimeType for this store is set, do not introspect a new one
      if (this.mimeType) {
        return this.mimeType
      }
      if (format === 'wav') {
        return 'audio/wav'
      } else if (format === 'mp3') {
        return 'audio/mpeg'
      } else if (format === 'mov') {
        return 'video/quicktime'
      } else if (format === 'webm') {
        return 'video/webm'
      } else {
        throw new Error('Unable to retrieve mime type.  Missing format information.')
      }
    }

    CloudStore.prototype.createUploadUrl = async function (projectId, recordingId, uploadId, hostId, path, isSoundboard) {
      var self = this
      dbg('Creating upload url.')
      var mimeType = this.getMimeType()
      const request = {
        gcs: {
          url: `${this.origin}/api/cloud-storage/upload-url`,
          body: JSON.stringify({
            mimeType: mimeType,
            projectId: projectId,
            // if this is a wav, we'll upload the file as a .pcm then servside processing will change that into a .wav
            path: path.endsWith('.wav') ? path.replace(/[.]wav$/, '.pcm') : path,
            action: 'write',
            isSoundboard: isSoundboard
          }),
          parseResponse: r => r.text(),
        },
        b2: {
          url: this.gatewayUploadCreatorUrl
            .replace(':projectId', projectId)
            .replace(':recordingId', recordingId)
            .replace(':uploadId', uploadId),
          body: JSON.stringify({
            hostId,
            path,
            contentType: mimeType,
            customHostForFinalizationNotification: this.customHostForFinalizationNotification,
          }),
          parseResponse: r => r.json().then(({ uploadUrl }) => uploadUrl),
        },
      }

      const fetchUploadUrl = () => fetch(request[self.provider].url, {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: request[self.provider].body
      })

      try {
        let res = await fetchUploadUrl()

        // Fallback to GCS if Backblaze fails to create the URL
        if (!res.ok && self.provider !== 'gcs') {
          self.provider = 'gcs'
          res = await fetchUploadUrl()
        }

        if (!res.ok) throw new Error('Create upload url request failed: ' + res.status)

        const url = await request[self.provider].parseResponse(res)

        self.uploadUrl = url
        return url
      } catch (err) {
        utils.logRethrow(err)
      }
    }

    CloudStore.prototype.createDownloadUrl = function (projectId, path, isSoundboard, bucketName) {
      var self = this
      dbg('Creating download url.')
      return fetch(this.origin + '/api/cloud-storage/download-url', {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          projectId,
          path,
          isSoundboard,
          bucketName,
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Create download url request failed: ' + res.status)
      }).then(function (url) {
        self.downloadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    CloudStore.prototype.delete = function (projectId, path, isSoundboard) {
      return  jwtClient.fetchWithJwt(this.origin + '/api/cloud-storage/delete', {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          projectId: projectId,
          path: path,
          isSoundboard: isSoundboard
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Delete request failed: ' + res.status)
      }).then(function (url) {
        self.downloadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    /**
     * Used to get progress for an upload
     * TODO: change this so it uses the method inside upload.ts
     * @return {Promise}
     */
    CloudStore.prototype.getResumableUploadProgress = function () {
      if (this.provider === 'b2')
        return Promise.resolve(this.parts.length * this.chunkSize)
      else if (this.provider !== 'gcs')
        throw new Error('Provider (gcs or b2) not set.')

      // Keep current behavior for gcs

      var self = this

      var uploader = new ResumableUploadGcs({
        url: this.uploadUrl,
        contentType: this.getMimeType()
      })

      return uploader.getUploadProgress().then(function (result) {
        self.bytesUploaded = result.bytesUploaded

        // if we get a 200 then the file is finalized and nothing can be added
        if (result.status === 200) {
          self.finalized = true
        }

        return result.bytesUploaded
      })
    }

    /**
     * Used to upload a blob to a GCS destination
     * It can start from 0 or from an offset
     * @param  {Object} options Structure: url, blob, [offset]
     * @return {Promise}
     */
    CloudStore.prototype.upload = async function (options) {
      const ResumableUpload = this.provider === 'b2' ? ResumableUploadB2 : ResumableUploadGcs
      this.uploader = new ResumableUpload({
        url: options.url,
        contentType: this.getMimeType(),
        progressCb: (uploaded) => {
          this.bytesUploaded = uploaded
          this.trigger('change:bytesUploaded', uploaded)
        }
      })

      // if we have an offset received, then we want this upload to start at that point
      if (options.offset) {
        this.bytesUploaded = options.offset
        this.uploader.bytesUploaded = options.offset
      }

      // we have only one chunk, so mark it as last
      const partNumber = this.parts.length + 1
      const { etag } = await this.uploader.uploadChunk(options.blob, partNumber, true)
      
      this.parts.push({ partNumber, etag })
      this.trigger('chunkUploaded', {
        etag,
        partNumber,
        isLastChunk: true,
        uploadedBytes: this.uploader.bytesUploaded
      })

      this.finalized = true
    }

    CloudStore.prototype.completeMultipartUpload = async function () {
      if (this.provider !== 'b2') return

      if (!this.uploader)
        this.uploader = new ResumableUploadB2({
          url: this.uploadUrl,
          contentType: this.getMimeType(),
          progressCb: (uploaded) => {
            this.bytesUploaded = uploaded
            this.trigger('change:bytesUploaded', uploaded)
          }
        })

      const result = await this.uploader.completeMultipartUpload(this.parts)
      this.uploader = null
      this.parts = []

      // We need to defer the trigger of this event, because there is a clean-up function
      // bound to it. When this function is running through a worker proxy, the proxy
      // wouldn't be able to return because the clean-up is cleaning the message ports.
      setTimeout(() => this.trigger('uploadMultipartCompleted'), 0)

      return result
    }

    events.mixin(CloudStore.prototype)

    return CloudStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = CloudStore
  else global.CloudStore = CloudStore
})()
